import './App.css';
import phimmtextlogo from "./img/logos/phimmtextlogo.png";
import sparkels from "./img/assets/sparkles.png";
import aboutus from "./img/assets/aboutus.png";
import goingout from "./img/assets/goingout.png";
import landingpagevideo from "./img/videos/1080-landing-page.mp4"

function App() {
  return (
    <div>
      <header>
        <a href="#">
          <img src={phimmtextlogo} alt="Phantom Immigration" id="logo" />
        </a>
        <input className="checkbox" type="checkbox" name="" id="checkbox-hamburger" onClick={() => {
          const navMenu = document.getElementById("nav");

          // function mobileNav() {
          navMenu.classList.toggle("mobile-open");
          // }
        }} />
        <div id="mobile-btn" className="hamburger-lines">
          <span className="line line1" />
          <span className="line line2" />
          <span className="line line3" />
        </div>
        <nav id="nav">
          <a href="#aboutcompany">About Us</a>
          <a href="#ourmetrics">Our Metrics</a>
          <a href="#connectwithus">Connect With Us</a>
        </nav>
      </header>
      <main>
        <section id="hero">
          <div id="clouds" />
          <article>
            <h1>Phantom Immigration</h1>
            <h2>Who are we?</h2>
            <p>Welcome to our company! We at <b>Phantom Immigration Pvt. Ltd.</b> are dedicated to providing top-notch immigration services to clients from around the world.
              We understand that navigating the complex and ever-changing world of immigration can be overwhelming,
              which is why we strive to make the process as smooth and stress-free as possible for our clients.
            </p>
            <a href="#take-control"><button className="btn">Learn More</button></a>
          </article>
          <div id="video-wrapper">
            <video controls autoPlay muted>
              <source src={landingpagevideo} type="video/mp4" />
            </video>
          </div>
          <aside>
            {/* TOASK */}
            {/* <p>Phantom Immigration is registered on  <a href="https://google.com/" target="_blank" rel="noreferrer">SomeRegistrationAuthority</a>.</p> */}
            <h5>Connect.</h5>
            <h5>Get Profiled.</h5>
            <h5>Immigrate.</h5>
          </aside>
        </section>
        <section className="center-form" id="take-control">
          <h3>Take Control of Your Career<img src={sparkels} className="sparkles-right" alt='sparkels' /></h3>
          <p>
            Immigrating can provide several opportunities for advancing your tech career.
            Many countries offer specialized programs and visas to attract skilled professionals in technology fields,
            such as <em><b> software engineering, data science, and cybersecurity</b></em>.
            By immigrating to a country with a strong tech industry, you can access new job opportunities and work for leading tech companies that can offer
            higher salaries, better benefits, and more significant career growth.
          </p>
          <p>
            Additionally, you can expand your professional network, learn new skills, and gain valuable international experience that can enhance your resume and increase your marketability to future employers.
            Immigrating can also provide access to resources and funding that may not be available in your home country.
            Many countries offer programs and grants to support innovation and entrepreneurship,
            allowing you to launch your own tech startup and gain exposure to new markets and customers.
          </p>
          <p>
            Finally, immigrating can provide opportunities for personal and professional growth, including exposure to new cultures, languages, and ways of thinking.
            Immigrating can be challenging, but it can also be a rewarding and transformative experience that can help you achieve your long-term career goals.
          </p>

          <article id="rev-model">
            <div>
              <span>100%</span>
              <figcaption>Supportive Trainers & Staff</figcaption>
            </div>
            <div>
              <span>95%</span>
              <figcaption>Conversions of immigrant applications</figcaption>
            </div>
            <div>
              <span>10+</span>
              <figcaption>Combined Industry Experience</figcaption>
            </div>
          </article>
        </section>
        <section id="aboutcompany" className="left-form">
          <article>
            <h4>About Us</h4>
            <p>As experienced immigration lawyers, we have helped countless individuals and families achieve their immigration goals,
              from obtaining visas and green cards to navigating complex legal issues.
              We have a deep understanding of the immigration system and stay up-to-date with the latest changes,
              to ensure that we are providing our clients with the most accurate and effective advice possible.
              At our company, we believe that every client deserves personalized attention and support.
              We take the time to listen to your unique situation and tailor our services to meet <em> your specific needs and goals</em>.
              We are always available to answer your questions and provide guidance throughout the immigration process.
            </p>
            {/* <a href="https://www.google.com/"> Google</a> */}
            {/* TODO */}
            {/* <a href="#ourmetrics"> ReachFromHere</a>.
            <a href="https://www.google.com/" target="_blank" rel="noreferrer"><button className="btn">Learn More
              <span>→</span></button></a> */}
          </article>
          <figure>
            <img src={aboutus} alt="About Us" />
          </figure>
        </section>
        <section id="ourmetrics" className="right-form">
          <article>
            <h4>Metrics</h4>
            <p>We have worked very hard to create the right environment for the trainees and applicants. This has resulted in conversion of 100's of applications getting selected over the span of 2 years! We are proud to say that the people you will be instructed by, have years of experience in the industry and have contacts with major companies all over the world. This not only means that you are in capable hands, but also have the reach of our professional network, which in turn gives you access to increased opportunitites!</p>
            <p><b>Happy Customers!</b></p>
          </article>
          <figure>
            <img src={goingout} alt="metrics" />
          </figure>
        </section>
        <section id="connectwithus" className="center-form">
          <h3><img src={sparkels} className="sparkles-left" alt='sparkles' />Connect With Us</h3>
          <p>At <b>Phantom Immigration</b>, we are committed to providing our clients with the highest level of immigration services and support.
            Whether you are looking to obtain a visa or green card, need help with immigration-related legal issues,
            or have questions about the immigration process, our team of experienced immigration lawyers is here to help.
            To get started, simply send out a mail to us by clicking the button below, and one of our representatives will be in touch with you shortly.
            You can also reach us by phone or email using the information provided on this page.
            We look forward to hearing from you and helping you achieve your immigration goals.</p>
          <p>Contact us now for exciting new paths! Don't let this opportunity slip past you.</p>
          <a href="mailto:info@phantomimmigration.com?subject=Query regarding Visa or IELTS/PTE Training"><button className="btn">Contact Us</button></a>
          <p>Give us a call: <b>+91 99915-37465, +91 93507-72447</b></p>
          <p>Have something more to say, email us at - <b>info@phantomimmigration.com</b></p>
        </section>
        <footer>
          <h3>Helping Dreamers</h3>
          <a href="https://google.com/" target="_blank" rel="noreferrer">
            {/* <span id='companynamefooter'>
            Phantom Immigration Pvt. Ltd.
              </span>  */}
            <img src={phimmtextlogo} alt='Phantom Immigration Pvt. Ltd.' />
          </a>
          <h6>
            Main Office: SCO 212, 2nd floor, Sector 10 Kurukshetra, Haryana, 136118
            <br />
            &#169; {new Date().getFullYear()} All rights Reserved.
          </h6>
        </footer>
      </main>
    </div>

  );
}

export default App;
